import React from 'react'
import Layout from '~components/layout'

const NotFoundPage = () => (
  <Layout>
    <h1> 404 NOT FOUND</h1>
    <p>Well... this is awkward....</p>
  </Layout>
)

export default NotFoundPage
